.flair {
  height: 45px;
  padding: 2px;
}

.flair_item {
  position:relative;
  display:inline-block;
}
.flair_badge{
  position: absolute;
  right:0px;
  bottom:0px;
  text-align: center;
  border-radius: 5px 5px 5px 5px;
  color:white;
  padding:2px 5px;
  font-size:10px;
}