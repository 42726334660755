.table_scroll { 
  height: 300px;
  position: relative;
  display: block;
}
.table_scroll .table {
  height: 300px;
}
.user_profile {
  height: 260px;
}
.user_profile .col {
  margin-bottom: 10px;
}
