.header_icon {
  font-size: 45px;
  line-height: 1;
  color: white;
  vertical-align: middle;
}
.header_image {
  height: 45px;
  vertical-align: middle;
}

@font-face {
    font-family: Oxygen;
    src: url('https://fonts.googleapis.com/css?family=Oxygen');
}
body{
    margin: 0;
}

.notification_item {
  position:relative;
  display:inline-block;
}
.notification_badge{
  position: absolute;
  right:-10px;
  top:0px;
  text-align: center;
  border-radius: 5px 5px 5px 5px;
  color:white;
  padding:2px 5px;
  font-size:10px;
  z-index: 100;
}