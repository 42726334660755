.avatar {
  height: 45px;
  width: 45px;
  object-fit: cover;
  vertical-align: baseline;
  border-radius: 50%;
}
.avatar_flair {
  height: 45px;
  padding: 2px;
}
.avatar_flair_item {
  position:relative;
  display:inline-block;
}
.avatar_flair_badge{
  position: absolute;
  left:0px;
  bottom:0px;
  z-index: 100;
}
.avatar_flair_badge img {
  width: 45px;
}
.avatar_champ_item {
  position:relative;
  display:inline-block;
}
.avatar_champ_badge{
  position: absolute;
  left:0px;
  top:-15px;
}
.avatar_champ_badge img {
  width: 45px;
}