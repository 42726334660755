.App {
  text-align: center;
  min-height: 400px;
  height: 100%;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.bg-gray {
  background-color: lightgray;
}

.error_message {
  font-weight: bold;
  color: red;
  text-align: left;
}

@media (min-width: 700px) {
  .container{
    max-width: 600px !important;
  }
}
.container{
  padding-top: 5px;
}
.avatar {
  height: 45px;
  width: 45px;
  object-fit: cover;
  vertical-align: baseline;
  border-radius: 50%;
}
.cursor_pointer {
  cursor: pointer;
}

.icon {
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
}
.icon svg {
  vertical-align: middle;
}

.flex-wrapper {
  display: flex;
  min-height: 100vh;
  flex-direction: column;
  justify-content: space-between
}