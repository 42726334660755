table thead>tr:first-of-type{
  background-color: var(--tertiary);
  color: white;
  border-color: var(--tertiary);
}
table thead>tr:last-of-type{
  background-color: var(--primary);
  color: white;
  border-color: var(--primary);
}
.leaderboard_table {
  max-height: 500px;
  overflow-y: auto;
  padding-right: 0px !important;
}